import React, { useState, useCallback, useContext, useEffect } from 'react';
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { Button,
         Box,
         Container,
         Popover,
         TextField,
         CircularProgress,  
         Typography } from "@material-ui/core/";
import InformationTable from "../components/informationTable";
import { QuestionnaireContext } from "../context/questionnaireContext";
import theme from '../gatsby-theme-material-ui-top-layout/theme';
import { graphql } from 'gatsby'

const useStyles = makeStyles(() => ({
    sendButton: {
      width: "150px",
      height: "58px",
      color: "white"
    },
    popover: {
      padding: theme.spacing(1)
    }
  }))

function ResultFromMail(props) {
    const {id} = props;
    const [data, setData] = useState(null);
    const {t} = useTranslation();

    const columns = [t("Questions"), t("Answers")]

    const rows = data !== null
    ?
      [
        [
       t("Should I undergo a screening test?"),
        t(data.answer.screening2 || "No answer")
        ],
        [
        t("Where to undergo my prenatal screening test?"),
        t(data.answer.screening3 || "No answer")
        ],
        [
        t("Which screening option is right for me?"),
        t(data.answer.screening4 || "No answer")
        ],
        [
        t("Should I undergo a diagnostic test?"),
        t(data.answer.diagnostic1 || "No answer")
        ] 
      ]
    :
      null

    useEffect(() => {
        fetch(`https://dev-ca.takecareapi.com/greybox-api/survey/answers/${id}`)
          .then(response => response.text())
          .then(result => setData(JSON.parse(result)))
          .catch(error => console.log('error', error));
      }, []);

    return (
      <Container maxWidth="md">
        {data === null 
        ?
        <span>
          <CircularProgress/>
        </span>
        :
        <Box marginTop="15px">
          <Typography gutterBottom={true}>
            Nous vous remercions d’avoir utilisé cet outil d’aide à la décision.
          </Typography>
          <InformationTable columns={columns} rows={rows} firstColumnWidth="40%"></InformationTable>
        </Box>
      }
      </Container>
  );
}

export default function Results(props) {
  const classes = useStyles();
  const{t} = useTranslation();
  const [userEmail, setUserEmail] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  let screening2 = "No answer";
  let screening3 = "No answer";
  let screening4 = "No answer";
  let diagnostic1 = "No answer";

  if (typeof window !== 'undefined') {
    screening2 = localStorage.getItem("screening2") || "No answer";
    screening3 = localStorage.getItem("screening3") || "No answer";
    screening4 = localStorage.getItem("screening4") || "No answer";
    diagnostic1 = localStorage.getItem("diagnostic1") || "No answer";
  }

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'thank-you-popover' : undefined;

  const sendEmail = useCallback(async (event) => {

    setAnchorEl(event.currentTarget);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"email":userEmail,
                              "answer":{
                                screening2: screening2,
                                screening3: screening3,
                                screening4: screening4,
                                diagnostic1: diagnostic1
                              },
                              "survey":"screening"});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://dev-ca.takecareapi.com/greybox-api/survey/answers/", requestOptions)
      .then(response => response.text())
      .catch(error => console.log('error', error));
  })

  const rows = [
    [
    t("Should I undergo a screening test?"),
    t(screening2)
    ],
    [
    t("Where to undergo my prenatal screening test?"),
    t(screening3)
    ],
    [
    t("Which screening option is right for me?"),
    t(screening4)
    ],
    [
    t("Should I undergo a diagnostic test?"),
    t(diagnostic1)
    ] 
  ]

  const columns = ["Questions", "Réponses"]


  return ( 
    <div>
      {Object.keys(props.params).length > 0 && props.params["*"] !== "" 
      ?
        <ResultFromMail id={props.params["*"]}/>
      :
      <Layout pageTitle={t("Results")}>
        <Box marginTop={theme.spacing(1)}>
          <InformationTable columns={columns} rows={rows} firstColumnWidth="40%"></InformationTable>
          <Typography gutterBottom={true}>
            {t("If you wish to save your answers, please specify your email address.")} 
          </Typography>
          <Box display="flex" flexDirection="row" paddingTop={theme.spacing(1)}>
              <Box marginTop="20px" width="50%" marginLeft="auto">
                  <TextField fullWidth={true} id="email" label={t("email")} variant="outlined" color="primary" onChange={handleEmailChange} />
              </Box>
              <Box alignSelf="flex-end" marginLeft= "5px">
                <Button className={classes.sendButton} variant="contained" color="primary" onClick={sendEmail} disabled={userEmail===""}>{t("Send")}</Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <Typography className={classes.popover}>
                  {t("Email sent!")}
                  <br/> 
                  {t("Thank you for using this decision making tool")}
                </Typography>
              </Popover>
            </Box>
          </Box>
        </Box>
      </Layout>
    }
  </div>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
